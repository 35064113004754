<template>
  <div>
    <TicketAdd
      :item-id.sync="itemId"
      :item-data.sync="itemData"
      :sidebar-active.sync="sidebarActive"
      @refetch-data="refetchData"
    />

    <TicketViewSidebar
      :item-id.sync="itemId"
      :item-data.sync="itemData"
      :sidebar-active.sync="sidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <app-collapse hover>
      <app-collapse-item title="Filters">
        <TicketListFilters
          :status-filter.sync="statusFilter"
          :status-options="ticketStatusOption"
          :category-filter.sync="categoryFilter"
          :ticket-categories-option="ticketCategoriesOption"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="ticketsMetaData.length && $can('ticket_delete')"
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedTickets.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Subject"
                />
              </b-input-group>

              <!-- <b-button
                v-if="$can('ticket_create')"
                variant="primary"
                :to="{ name: 'add-ticket' }"
              >
                <span class="text-nowrap">Add Ticket</span>
              </b-button> -->
              <b-button
                v-if="$can('ticket_create')"
                variant="primary"
                @click="addNew()"
                v-b-toggle.add-sidebar
              >
                <span class="text-nowrap">New Ticket</span>
              </b-button>
            </div>
            <b-button
              v-else-if="$can('ticket_delete')"
              class="float-right"
              variant="danger"
              :disabled="ticketsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ ticketsDeleteCount }} Ticket</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedTickets"
        name="selectedTickets"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchTicketList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <template #cell(ticket_id)="data">
            <b-link
                v-if="$can('ticket_show')"
                v-b-toggle.ticketViewSidebar
                @click="viewRecord(data)"
                class="font-weight-bold text-danger d-inline-block text-nowrap text-uppercase"
              >
                {{ data.item.ticket_id }}
              </b-link>
              <span
                v-else
                class="text-uppercase text-danger"
              >
                {{ data.item.ticket_id }}
              </span>
          </template>

          <template #cell(user)="data">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.user.avatar"
                  :text="avatarText(data.item.user.name)"
                />
              </template>
              <b-link
                v-if="$can('employee_show')"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.user.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.user.name"
              >
                {{ data.item.user.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                class="d-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.user.name"
              >
                {{ data.item.user.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!-- Column: User -->
          <template #cell(support_category.name)="data">
            <b-media vertical-align="center" v-if="data.item.support_category">
              <b-link
                v-if="$can('ticket_show')"
                v-b-toggle.ticketViewSidebar
                @click="viewRecord(data)"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.support_category.name"
              >
                {{ data.item.support_category.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                v-b-tooltip.hover.topright.v-primary
                :title="data.item.support_category.name"
              >
                {{ data.item.support_category.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
          </template>
          <template #cell(updated_at)="data">
            {{ data.item.updated_at | formatDate }}
          </template>

          <template #cell(subject)="data">
            {{ data.item.subject | str_limit(15) }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              @click="editRecord(data)"
              v-if="$can('ticket_edit')"
              v-b-toggle.add-sidebar
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="viewRecord(data)"
              v-if="$can('ticket_show')"
              v-b-toggle.ticketViewSidebar
              v-b-tooltip.hover
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />

            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('ticket_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="selectedTickets.length == 0"
              v-model="currentPage"
              :total-rows="totalTicketList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('ticket_delete')"
              class="float-right"
              variant="danger"
              :disabled="ticketsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ ticketsDeleteCount }} Ticket</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ticketStoreModule from "../ticketStoreModule";
import useTicketList from "./useTicketList";
import TicketListFilters from "./TicketListFilters.vue";
import TicketAdd from "./TicketAdd.vue";
import TicketViewSidebar from "./TicketViewSidebar.vue";
import { avatarText } from "@core/utils/filter";
export default {
  components: {
    TicketListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    ToastificationContent,
    BFormCheckbox,
    BFormCheckboxGroup,
    TicketAdd,
    TicketViewSidebar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-tickets";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedTickets = ref([]);
    const ticketsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME, ticketStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchTicketList,
      tableColumns,
      perPage,
      currentPage,
      totalTicketList,
      ticketsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByTicketUrgencyStatus,
      filterByTicketType,
      resolveUserStatusVariant,
      ticketStatusOption,
      categoryFilter,
      ticketCategoriesOption,
    } = useTicketList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedTickets.value = [];
      if (val) {
        ticketsMetaData.value.forEach((ticket) => {
          selectedTickets.value.push(ticket.hashid);
        });
      }
    });

    watch(selectedTickets, (val) => {
      ticketsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchTicketList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalTicketList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByTicketUrgencyStatus,
      filterByTicketType,
      selectedTickets,
      ticketsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      ticketStatusOption,
      categoryFilter,
      ticketCategoriesOption,
      ticketsMetaData,
      // Filter
      avatarText,
    };
  },
  data() {
    return {
      itemId: "",
      itemData: {},
      sidebarActive: false,
    };
  },
  watch: {
    sidebarActive: function (newVal, oldVal) {
      this.refetchData();
    },
  },
  created() {
    if (!this.$can("ticket_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
  },
  methods: {
    addNew() {
      const self = this;
      self.itemId = "";
      self.itemData = {
        title: "",
      };
      self.sidebarActive = true;
      console.log("add new");
    },
    editRecord(data) {
      const self = this;
      self.itemData = data.item;
      self.itemId = data.item.hashid;
    },
    viewRecord(data) {
      const self = this;
      self.itemData = data.item;
      self.itemId = data.item.hashid;
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.subject}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedTickets.length} Ticket, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-tickets/removeMultipleTickets", this.selectedTickets)
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedTickets = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully.",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ticket Deletion failed.",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchTicketsData() {},
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-tickets/removeTicket", id)
        .then((res) => {
          if (res.status == "204") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Ticket Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Ticket Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Ticket Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
